var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFlag),expression:"showFlag"}],staticClass:"msg-login"},[_c('h',{attrs:{"iscancel":true},on:{"cancel":_vm.hide}},[_c('span',{staticClass:"name",domProps:{"textContent":_vm._s(_vm.name)}})]),_c('div',{staticClass:"text-list"},[_c('div',[_c('span',{staticClass:"name"},[_vm._v("竞猜主题：")]),_c('span',{staticClass:"inner",domProps:{"textContent":_vm._s(_vm.data.guessTheme)}})]),_c('div',[_c('span',{staticClass:"name"},[_vm._v("竞猜选项：")]),_c('span',{staticClass:"inner",domProps:{"textContent":_vm._s(((_vm.data.itemOneContent) + "vs" + (_vm.data.itemTwoContent)))}})]),_c('div',[_c('span',{staticClass:"name"},[_vm._v("竞猜ID：")]),_c('span',{staticClass:"inner",domProps:{"textContent":_vm._s(_vm.data.guessComId)}})]),_c('div',[_c('span',{staticClass:"name"},[_vm._v("直播间：")]),_c('span',{staticClass:"inner",domProps:{"textContent":_vm._s(_vm.data.liveChannalName)}})]),_c('div',[_c('span',{staticClass:"name"},[_vm._v("投注项：")]),_c('span',{staticClass:"inner",domProps:{"textContent":_vm._s(
            _vm.data.choiceItem == 1 ? _vm.data.itemOneContent : _vm.data.itemTwoContent
          )}})]),_c('div',[_c('span',{staticClass:"name"},[_vm._v("获胜方：")]),(_vm.data.modifyResultType > 0)?_c('span',{staticClass:"inner"},[_vm._v(" "+_vm._s(_vm.data.modifyResultType == 1 ? _vm.data.itemOneContent : _vm.data.modifyResultType == 2 ? _vm.data.itemTwoContent : _vm.data.modifyResultType == 3 ? "流局" : "")+" "),_c('b',{staticClass:"modify"},[_vm._v("(修改)")])]):_c('span',{staticClass:"inner",domProps:{"textContent":_vm._s(
            _vm.data.resultType == 1
              ? _vm.data.itemOneContent
              : _vm.data.resultType == 2
              ? _vm.data.itemTwoContent
              : _vm.data.resultType == 3
              ? '流局'
              : ''
          )}}),_c('div',[_c('span',{staticClass:"name"},[_vm._v("赔率：")]),(_vm.data.modifyResultType > 0)?_c('span',{staticClass:"inner",domProps:{"textContent":_vm._s(
              _vm.data.modifyResultType == 1
                ? _vm.data.oddsItemOne
                : _vm.data.modifyResultType == 2
                ? _vm.data.oddsItemTwo
                : '-'
            )}}):_c('span',{staticClass:"inner",domProps:{"textContent":_vm._s(
              _vm.data.resultType == 1
                ? _vm.data.oddsItemOne
                : _vm.data.resultType == 2
                ? _vm.data.oddsItemTwo
                : '-'
            )}})])]),_c('div',[_c('span',{staticClass:"name"},[_vm._v("投注：")]),_c('span',{staticClass:"inner",domProps:{"textContent":_vm._s(_vm.data.betChips)}})]),_c('div',[_c('span',{staticClass:"name"},[_vm._v("盈亏：")]),_c('span',{staticClass:"inner",class:{
            profit: _vm.parseProfit(_vm.data.profit, _vm.data.deficit) > 0,
            deficit: _vm.parseProfit(_vm.data.profit, _vm.data.deficit) <= 0
          },domProps:{"textContent":_vm._s(_vm.parseProfit(_vm.data.profit, _vm.data.deficit))}})]),_c('div',[_c('span',{staticClass:"name"},[_vm._v("时间：")]),_c('span',{staticClass:"inner",domProps:{"textContent":_vm._s(_vm.data.betTime)}})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }