<template>
  <transition name="slide">
    <div class="guess-container">
      <h :isback="true">
        <span class="name">我的竞猜</span>
      </h>
      <div class="main">
        <div class="date border-bottom-1px">
          参与记录<span @click="showDatePicker"
            >{{ `${year}-${month}` }}<i class="cubeic-arrow"
          /></span>
        </div>
        <div v-if="list.length" class="wrap">
          <cube-scroll
            ref="scroll"
            :data="list"
            :options="options"
            @pulling-down="onPullingDown"
            @pulling-up="onPullingUp"
          >
            <ul class="list">
              <li
                v-for="item in list"
                :key="item.id"
                class="border-bottom-1px"
                @click="showDetails(item)"
              >
                <div class="l">
                  <div class="theme">
                    <span class="name" v-text="item.guessTheme" />
                    <span
                      class="choose"
                      v-text="
                        item.choiceItem == 1
                          ? `我投(${item.itemOneContent})`
                          : `我投(${item.itemTwoContent})`
                      "
                    />
                  </div>
                  <div class="room" v-text="item.liveChannalName" />
                </div>
                <div class="r">
                  <div class="time" v-text="item.betTime" />
                  <div
                    class="profit"
                    :class="{
                      profit: parseProfit(item.profit, item.deficit) > 0,
                      deficit: parseProfit(item.profit, item.deficit) <= 0
                    }"
                    v-text="parseProfit(item.profit, item.deficit)"
                  />
                </div>
              </li>
            </ul>
          </cube-scroll>
        </div>
        <div v-else class="not-data">
          <img src="./img/not-data.png" width="109" />
          <div class="txt">暂无数据</div>
        </div>
      </div>
      <guess-details ref="details" name="投注详情" :data="details" />
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import GuessDetails from "@/components/user/guess-details";
import { getJoinGuessList } from "@/api/user";
export default {
  components: {
    h,
    GuessDetails
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 20,
      totalPages: 1,
      list: [],
      now: new Date(),
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      options: {
        click: true,
        pullDownRefresh: {
          txt: "更新成功"
        },
        pullUpLoad: {
          threshold: 0,
          stopTime: 1000,
          txt: "数据加载成功"
        }
      },
      details: {}
    };
  },
  created() {
    this._getJoinGuessList();
  },
  methods: {
    _getJoinGuessList() {
      const params = {
        startDate: `${this.year}-${this.month}-1`,
        endDate: `${this.year}-${this.month}-${this.getMonthEnd()}`,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      getJoinGuessList(params).then(res => {
        if (!res) {
          return;
        }
        if (this.pageNumber > 1) {
          this.list = this.list.concat(res.object.myGuessRecordList);
        } else {
          this.list = res.object.myGuessRecordList;
        }
        this.totalPages = res.object.totalPages;
      });
    },
    showDatePicker() {
      if (!this.datePicker) {
        this.datePicker = this.$createDatePicker({
          title: "",
          min: new Date(2018, 6, 8),
          max: this.now,
          value: this.now,
          format: { year: "YYYY", month: "M", date: "" },
          onSelect: this.selectHandle
        });
      }
      this.datePicker.show();
    },
    selectHandle(date, selectedVal) {
      this.year = selectedVal[0];
      this.month = selectedVal[1];
      this._getJoinGuessList();
    },
    getMonthEnd() {
      return new Date(this.year, this.month, 0).getDate();
    },
    onPullingDown() {
      this.pageNumber = 1;
      this._getJoinGuessList();
    },
    onPullingUp() {
      if (this.pageNumber >= this.totalPages) {
        this.$refs.scroll.forceUpdate();
        return;
      }
      this.pageNumber++;
      this._getJoinGuessList();
    },
    parseProfit(a, b) {
      return a + b;
    },
    showDetails(item) {
      this.details = item;
      this.$refs.details.show();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.guess-container
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .main
    .date
      height 56px
      line-height 56px
      padding 0 16px
      font-size 14px
      span
        display inline-block
        height 19px
        line-height 19px
        padding 0 10px
        background #1E88E4
        border-radius 10px
        margin-left 5px
        text-align center
        color #fff
    .wrap
      height calc(100vh - 36px - 56px)
      .list
        li
          display flex
          align-items center
          padding 18px 15px 16px
          .l
            flex 0 0 60%
            .theme
              .name
                font-size 14px
              .choose
                font-size 12px
                color #F41D08
            .room
              color #6B6B6B
          .r
            flex 1
            text-align right
            .time
              font-size 13px
              color #CECECE
            .profit
              font-size 17px
              color #FD9427
            .deficit
              font-size 17px
              color #6B6B6B
  .not-data
    text-align center
    margin-top 120px
    .txt
      margin-top 20px
      font-size 17px
      color #CECECE
</style>
