<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="msg-login">
      <h :iscancel="true" @cancel="hide">
        <span class="name" v-text="name" />
      </h>
      <div class="text-list">
        <div>
          <span class="name">竞猜主题：</span>
          <span class="inner" v-text="data.guessTheme" />
        </div>
        <div>
          <span class="name">竞猜选项：</span>
          <span
            class="inner"
            v-text="`${data.itemOneContent}vs${data.itemTwoContent}`"
          />
        </div>
        <div>
          <span class="name">竞猜ID：</span>
          <span class="inner" v-text="data.guessComId" />
        </div>
        <div>
          <span class="name">直播间：</span>
          <span class="inner" v-text="data.liveChannalName" />
        </div>
        <div>
          <span class="name">投注项：</span>
          <span
            class="inner"
            v-text="
              data.choiceItem == 1 ? data.itemOneContent : data.itemTwoContent
            "
          />
        </div>
        <div>
          <span class="name">获胜方：</span>
          <span v-if="data.modifyResultType > 0" class="inner">
            {{
              data.modifyResultType == 1
                ? data.itemOneContent
                : data.modifyResultType == 2
                ? data.itemTwoContent
                : data.modifyResultType == 3
                ? "流局"
                : ""
            }}
            <b class="modify">(修改)</b>
          </span>
          <span
            v-else
            class="inner"
            v-text="
              data.resultType == 1
                ? data.itemOneContent
                : data.resultType == 2
                ? data.itemTwoContent
                : data.resultType == 3
                ? '流局'
                : ''
            "
          />
          <div>
            <span class="name">赔率：</span>
            <span
              v-if="data.modifyResultType > 0"
              class="inner"
              v-text="
                data.modifyResultType == 1
                  ? data.oddsItemOne
                  : data.modifyResultType == 2
                  ? data.oddsItemTwo
                  : '-'
              "
            />
            <span
              v-else
              class="inner"
              v-text="
                data.resultType == 1
                  ? data.oddsItemOne
                  : data.resultType == 2
                  ? data.oddsItemTwo
                  : '-'
              "
            />
          </div>
        </div>
        <div>
          <span class="name">投注：</span>
          <span class="inner" v-text="data.betChips" />
        </div>
        <div>
          <span class="name">盈亏：</span>
          <span
            class="inner"
            :class="{
              profit: parseProfit(data.profit, data.deficit) > 0,
              deficit: parseProfit(data.profit, data.deficit) <= 0
            }"
            v-text="parseProfit(data.profit, data.deficit)"
          />
        </div>
        <div>
          <span class="name">时间：</span>
          <span class="inner" v-text="data.betTime" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    h
  },
  data() {
    return {
      showFlag: false
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    },
    parseProfit(a, b) {
      return a + b;
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.msg-login
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .text-list
    margin 10px 15px 0
    font-size 14px
    line-height 1.5
    .name
      color #6B6B6B
    .profit
      color #FD9427
</style>
